<template>
  <div>
    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn to="/admin/commiteevecreport" class="mr-2">
        <span>รายงานการแต่งตั้งคณะกรรมการตรวจสอบ </span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>

      <v-btn to="/admin/commiteevecpro">
        <span>รายชื่อคณะกรรมการ</span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="คณะกรรมการตรวจสอบศักยภาพของผู้ประสงค์ขอย้าย ตำแหน่งครู (การย้ายกรณีปกติ)"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" lg="6" class="text-right">
              <v-btn
                color="info"
                :href="
                  '#/admin/print_commiteevecproOrder/' +
                    periods.period_vecprovince_year +
                    '/' +
                    periods.period_vecprovince_times
                "
                target="_blank"
              >
                <v-icon>mdi-printer</v-icon>
                พิมพ์รายชื่อคณะกรรมการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="commiteevecpros"
          :search="search"
        >
          <template v-slot:item.commiteevecproviceIDCard="{ item }">
            {{ item.title_s + "" + item.frist_name + " " + item.last_name }}
            <v-btn
            color="warning"
              text
              @click="
                commiteevecproEdit(
                  item.commiteevecproviceID,
                  item.commiteeVecProYear,
                  item.commiteeVecProTime,
                  item.commiteeVecProIdCard
                )
              "
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
          </template>
          <template v-slot:item.commiteeVecProPosition="{ item }">
            <span v-if="item.commiteeVecProPosition === '1'">
              ประธานกรรมการ</span
            >
            <span v-if="item.commiteeVecProPosition === '2'">
              กรรมการ ลำดับที่ 1</span
            >
            <span v-if="item.commiteeVecProPosition === '3'">
              กรรมการ ลำดับที่ 2</span
            >
            <span v-if="item.commiteeVecProPosition === '4'">
              กรรมการ ลำดับที่ 3</span
            >
            <span v-if="item.commiteeVecProPosition === '5'">
              กรรมการ ลำดับที่ 4</span
            >
            <span v-if="item.commiteeVecProPosition === '6'"> เลขานุการ</span>

            <v-btn
            color="warning"
              text
              @click="
                commiteevecproPositonEdit(
                  item.commiteevecproviceID,
                  item.commiteeVecProYear,
                  item.commiteeVecProTime,
                  item.commiteeVecProIdCard
                )
              "
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
            
          </template>
          <template v-slot:item.commiteevecproStatus="{ item }">
            <div class="text-center">
              <v-chip
                dark=""
                color="red"
                v-if="item.commiteevecproStatus === '0'"
              >
                <v-icon> mdi-close</v-icon>
              </v-chip>
              <v-chip v-if="item.commiteevecproStatus === '1'">
                <v-icon color="green"> mdi-content-save</v-icon>
              </v-chip>
            </div>
          </template>

          <template v-slot:item.action_s="{ item }">
            <div class="text-center">
              <v-btn
              color="red"
                text
                @click="
                  commiteevecproDelete(
                    item.commiteevecproviceID,
                    item.commiteeVecProYear,
                    item.commiteeVecProTime,
                    item.commiteeVecProIdCard
                  )
                "
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model deletecommiteevecprodialog -->
      <v-layout>
        <v-dialog
          v-model="deletecommiteevecprodialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูล"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text class="">
              <v-form ref="deletecommiteevecproform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันการลบข้อมูล :
                      <h3>
                        {{
                          editcommiteevecpro.frist_name +
                            " " +
                            editcommiteevecpro.last_name
                        }}
                        {{ editcommiteevecpro.commiteeVecProIdCard }}
                      </h3>
                    </v-flex>
                    <v-flex xs12 md6></v-flex>
                    <v-flex xs12 md6></v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletecommiteevecprodialog = false">
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletecommiteevecproubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editcommiteevecprodialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editcommiteevecprodialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขกรรมการ"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text>
              <v-form ref="editcommiteevecproform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          ปี
                          {{ periods.period_vecprovince_year }}
                          ครั้งที่ {{ periods.period_vecprovince_times }} <br />
                          กรรมการ
                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_teacher'
                            "
                            >พิจารณาย้ายสายการสอน</span
                          >

                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_manage'
                            "
                            >พิจารณาย้ายสายบริหาร</span
                          >
                        </v-col>
                        <v-col cols="12" md="6">
                          <h2 class="text-center">
                            {{
                              editcommiteevecpro.frist_name +
                                " " +
                                editcommiteevecpro.last_name
                            }}
                          </h2>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            :items="personnel_temporarys"
                            item-text="frist_names"
                            item-value="id_card"
                            v-model="updatecommiteevecpro.commiteeVecProIdCard"
                            prepend-icon="mdi-account"
                            dense
                            label="ค้นหา : ชื่อ-นามสกุล"
                            request
                            :rules="[v => !!v || '']"
                            rounded
                            outlined
                          >
                            <template v-slot:item="{ item }">
                              <div class="pa-2">
                                <div class="font-weight-black">
                                  {{ item.frist_names }}
                                </div>

                                <div class="divTab50">
                                  {{ item.position_name }} :
                                  {{ item.college_name }}
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="personnel_temporaryschecks.frist_names"
                        >
                          <v-alert dense text type="success">
                            {{ personnel_temporaryschecks.frist_names }} ตำแหน่ง
                            {{ personnel_temporaryschecks.position_name }}
                            <br />
                            {{ personnel_temporaryschecks.college_name }}
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editcommiteevecprodialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editcommiteevecproSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไขข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editcommiteevecproPositiondialog -->
        <v-layout row justify-center>
        <v-dialog v-model="editcommiteevecproPositiondialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขกรรมการ"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text>
              <v-form ref="editcommiteevecproPositionform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          ปี
                          {{ periods.period_vecprovince_year }}
                          ครั้งที่ {{ periods.period_vecprovince_times }} <br />
                          กรรมการ
                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_teacher'
                            "
                            >พิจารณาย้ายสายการสอน</span
                          >

                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_manage'
                            "
                            >พิจารณาย้ายสายบริหาร</span
                          >
                        </v-col>
                        <v-col cols="12" md="6">
                          <h2 class="text-center">
                            {{
                              editcommiteevecpro.frist_name +
                                " " +
                                editcommiteevecpro.last_name
                            }}
                          </h2>
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-select
                            v-model="editcommiteevecpro.commiteeVecProPosition"
                            :items="commiteevecpro"
                            item-text="text"
                            item-value="value"
                            prepend-icon="mdi-access-point"
                            dense
                            label="ตำแหน่ง : ประธานกรรมการ / กรรมการ"
                            request
                            :rules="[v => !!v || '']"
                            rounded
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="personnel_temporaryschecks.frist_names"
                        >
                          <v-alert dense text type="success">
                            {{ personnel_temporaryschecks.frist_names }} ตำแหน่ง
                            {{ personnel_temporaryschecks.position_name }}
                            <br />
                            {{ personnel_temporaryschecks.college_name }}
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editcommiteevecproPositiondialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editcommiteevecproPositionSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไขข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

     <!--  V-model Comfirmcommiteevecprodialog -->
    <!--   <v-layout>
        <v-dialog
          v-model="Comfirmcommiteevecprodialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="primary"
              icon="mdi-account-check"
              title="ยืนยันรายชื่อคณะกรรมการ"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text class="">
              <v-form ref="comfirmcommiteevecproform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันรายชื่อคณะกรรมการ :

                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="headers"
                        :items="commiteevecpros"
                        :search="search"
                      >
                        <template v-slot:item="{ item, index }">
                          <tr>
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                              {{ item.user_lastname }}
                            </td>
                            <td class="text-center">
                              {{ item.commiteeVecProYear }}
                            </td>
                            <td>{{ item.commiteeVecProTime }}</td>
                            <td>
                              {{
                                item.title_s +
                                  "" +
                                  item.frist_name +
                                  " " +
                                  item.last_name
                              }}
                            </td>
                            <td class="text-center">
                              <span v-if="item.commiteeVecProPosition === '1'">
                                ประธานกรรมการ</span
                              >
                              <span v-if="item.commiteeVecProPosition === '2'">
                                กรรมการ ลำดับที่ 1</span
                              >
                              <span v-if="item.commiteeVecProPosition === '3'">
                                กรรมการ ลำดับที่ 2</span
                              >
                              <span v-if="item.commiteeVecProPosition === '4'">
                                กรรมการ ลำดับที่ 3</span
                              >
                              <span v-if="item.commiteeVecProPosition === '5'">
                                กรรมการ ลำดับที่ 4</span
                              >
                              <span v-if="item.commiteeVecProPosition === '6'">
                                เลขานุการ</span
                              >
                            </td>
                            <td class="text-center">
                              <v-icon
                                color="yellow"
                                @click.stop="
                                  commiteevecproEdit(
                                    item.commiteevecproviceID,
                                    item.commiteeVecProYear,
                                    item.commiteeVecProTime,
                                    item.commiteeVecProIdCard
                                  )
                                "
                              >
                                mdi-pencil
                              </v-icon>
                            </td>
                            <td class="text-center">
                              <v-icon
                                color="red"
                                @click.stop="
                                  commiteevecproDelete(
                                    item.commiteevecproviceID,
                                    item.commiteeVecProYear,
                                    item.commiteeVecProTime,
                                    item.commiteeVecProIdCard
                                  )
                                "
                              >
                                mdi-delete
                              </v-icon>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-flex>
                    <v-flex xs12 md6></v-flex>
                    <v-flex xs12 md6></v-flex>
                  </v-layout>
                </v-container>
              </v-form>
              <div class="red--text">
                *เมื่อกดปุ่มบันทึก ยืนยันรายการ ท่านจะไม่สามารถแก้ไขข้อมูลใดๆ
                ได้ กรุณาตรวจสอบให้ถูกต้องเรียบร้อยก่อนการบันทึกยืนยันรายการ
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="Comfirmcommiteevecprodialog = false">
                <v-icon dark>mdi-close</v-icon>ปิด
              </v-btn>
              <v-btn
                large
                color="primary"
                @click.stop="confirmcommiteevecproubmit()"
                dark
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก ยืนยันรายการ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout> -->
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ApiKey: "HRvec2021",
      loading: true,
      updateImageDialog: false,
      search: "",
      addcommiteevecprodialog: false,
      editcommiteevecprodialog: false,
      deletecommiteevecprodialog: false,
      Comfirmcommiteevecprodialog: false,
      editcommiteevecproPositiondialog:false,
      showimagedialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      currentPK: null,
      headers: [
        { text: "สอจ.", align: "center", value: "user_lastname" },
        { text: "ปี", align: "center", value: "commiteeVecProYear" },
        { text: "ครั้งที่", align: "center", value: "commiteeVecProTime" },
        {
          text: "ชื่อ-นามสกุล",
          align: "left",
          value: "commiteevecproviceIDCard"
        },
        { text: "ตำแหน่ง", align: "center", value: "commiteeVecProPosition" },
        { text: "สถานะ", align: "center", value: "commiteevecproStatus" },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {},
      commiteevecpro: [],
      addcommiteevecpro: {},
      editcommiteevecpro: [],
      updatecommiteevecpro: {},
      commiteevecpros: [],
      commiteevecpro_sub: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      commiteevecpro: [
        { text: "ประธานกรรมการ", value: "1" },
        { text: "กรรมการ ลำดับที่ 1", value: "2" },
        { text: "กรรมการ ลำดับที่ 2", value: "3" },
        { text: "กรรมการ ลำดับที่ 3", value: "4" },
        { text: "กรรมการ ลำดับที่ 4", value: "5" },
        { text: "เลขานุการ", value: "6" }
      ],
      personnel_temporaryschecks: [],
      periods: [],
      personnel_temporarys: [],
      user: [],
      commiteevecproStatusC: {},
      commiteevecproStatusRP: {},
      commiteevecreports: [],
      rules: [
        value => !value || value.size < 1048576 || "ไฟล์ไม่เกิน 1 MB / 1024 KB"
      ],
      updateFilecommiteevecreports: {},
      documentsLinkfiles: null,
      enabled: false
    };
  },

  async mounted() {
    let result;
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    result = await this.$http.post("admin.php", {
      user_name: userSession.user_name,
      provice: "check",
      ApiKey: "HRvec2021"
    });
    this.user = result.data;
    await this.periodQuery();
    await this.commiteevecprosQueryAll();
  },

  methods: {
    async commiteevecproEdit(
      commiteevecproviceID,
      commiteeVecProYear,
      commiteeVecProTime,
      commiteeVecProIdCard
    ) {
      let result = await this.$http.post("commiteevecpro.php", {
        ApiKey: this.ApiKey,
        commiteevecproviceID: commiteevecproviceID,
        commiteeVecProYear: commiteeVecProYear,
        commiteeVecProTime: commiteeVecProTime,
        commiteeVecProIdCard: commiteeVecProIdCard
      });
      this.editcommiteevecpro = result.data;
      this.personnel_temporaryQueryAll(
        this.editcommiteevecpro.commiteevecproviceID
      );

      this.editcommiteevecprodialog = true;
    },

    async personnel_temporaryQueryAll(commiteevecproviceID) {
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          province_ID: commiteevecproviceID
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovince_type: "discuss_teacher"
      });
      this.periods = result_period.data;
    },

    async commiteevecprosQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("commiteevecpro.php", {
          ApiKey: this.ApiKey,
          commiteeVecProYear: this.periods.period_vecprovince_year,
          commiteeVecProTime: this.periods.period_vecprovince_times
        })
        .finally(() => (this.loading = false));
      this.commiteevecpros = result.data;
    },

    async editcommiteevecproSubmit() {
      if (this.$refs.editcommiteevecproform.validate()) {
        this.updatecommiteevecpro.ApiKey = this.ApiKey;
        this.updatecommiteevecpro.commiteevecproviceID = this.editcommiteevecpro.commiteevecproviceID;
        this.updatecommiteevecpro.commiteeVecProYear = this.editcommiteevecpro.commiteeVecProYear;
        this.updatecommiteevecpro.commiteeVecProTime = this.editcommiteevecpro.commiteeVecProTime;
        this.updatecommiteevecpro.commiteeVecProPosition = this.editcommiteevecpro.commiteeVecProPosition;

        
        let result = await this.$http.post(
          "commiteevecpro.updateCommit.php",
          this.updatecommiteevecpro
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteevecprosQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editcommiteevecprodialog = false;
      }
    },



    async commiteevecproPositonEdit(
      commiteevecproviceID,
      commiteeVecProYear,
      commiteeVecProTime,
      commiteeVecProIdCard
    ) {
      let result = await this.$http.post("commiteevecpro.php", {
        ApiKey: this.ApiKey,
        commiteevecproviceID: commiteevecproviceID,
        commiteeVecProYear: commiteeVecProYear,
        commiteeVecProTime: commiteeVecProTime,
        commiteeVecProIdCard: commiteeVecProIdCard
      });
      this.editcommiteevecpro = result.data;
      this.personnel_temporaryQueryAll(
        this.editcommiteevecpro.commiteevecproviceID
      );

      this.editcommiteevecproPositiondialog = true;
    },


  

    async editcommiteevecproPositionSubmit() {
      if (this.$refs.editcommiteevecproPositionform.validate()) {
        this.editcommiteevecpro.ApiKey = this.ApiKey;  
        
        let result = await this.$http.post(
          "commiteevecpro.update.php",
          this.editcommiteevecpro
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteevecprosQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editcommiteevecproPositiondialog = false;
      }
    },


    async commiteevecproDelete(
      commiteevecproviceID,
      commiteeVecProYear,
      commiteeVecProTime,
      commiteeVecProIdCard
    ) {
      let result = await this.$http.post("commiteevecpro.php", {
        ApiKey: this.ApiKey,
        commiteevecproviceID: commiteevecproviceID,
        commiteeVecProYear: commiteeVecProYear,
        commiteeVecProTime: commiteeVecProTime,
        commiteeVecProIdCard: commiteeVecProIdCard
      });
      this.editcommiteevecpro = result.data;
      this.deletecommiteevecprodialog = true;
    },

    async deletecommiteevecproubmit() {
      if (this.$refs.deletecommiteevecproform.validate()) {
        this.editcommiteevecpro.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "commiteevecpro.delete.php",
          this.editcommiteevecpro
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteevecprosQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ลบข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }   
        this.deletecommiteevecprodialog = false;
      }
    }
  },

  computed: {
    checkEventAss() {
      let data = this.commiteevecpros;
      let sum = 0;
      data.forEach(value => {
        sum = sum + parseInt(value[4]);
      });
      return sum;
    },

    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "#1D386A";
    },
    date_today_cal() {
      let today = new Date();
      return today;
    }
  }
};
</script>
<style>
.v-data-table thead th {
  font-size: 16px !important;
}
</style>
